<template>

<div>
    <button class="mb-2" v-on:click="gerarf2b">Gerar Cobrança F2b (Boleto, debito ou pix) </button> 
    <b-spinner v-show="loading" variant="success" ></b-spinner>
    <div ref="paypal"></div>
    <div v-if="cobgerada">
        <b-alert variant="success" show> 
            Este mesmo link <b-link :href="linkf2b" target="_blank"> {{linkf2b}} </b-link> foi enviado pro seu email.
        </b-alert>
	</div>
</div>

</template>

<script>
import { mapGetters } from "vuex";
export default {
    data: function() {
        return {
        loading:false,  
        cobgerada: false,
        linkf2b:''
        }  
    },
    computed: {
        ...mapGetters({
            baseurl:'geturlbackend',
            empid:'getempresa',
            itensCarrinho: "getCartItems",
            Total:'getTotalCarrinho',
            CodFrete:'getCodFrete',
            DescFrete:'getdescfrete',
            fretegratis:'getisfretegratis',
            Frete:'getFrete',
            QtdCarrinho:'getQtdCarrinho',
            ValorFrete:'getFrete',
            ClientId:'getClientId'
            
        }),
        
        Liquido() {
           // console.log('Total '+this.Total+' Frete '+this.ValorFrete)
            let liq = parseInt(this.Total) + parseInt(this.ValorFrete) 
           // console.log(liq)
            return liq
        }
   },
   methods: {
       gerarf2b() {
           let tipoenv = 3
           if (!this.fretegratis) {
               if (this.CodFrete == '') {
                    this.$bvModal.msgBoxOk('Frete Deve ser Calculado Antes !')
                    return false
               } else {
                  tipoenv = this.CodFrete
               }
               
           }


            const urlv = this.baseurl +'ajax_valida_estoque.php'
            var dados = 'qtditens='+this.QtdCarrinho
            for (let i = 0; i < this.itensCarrinho.length; i++) {
                            let item = this.itensCarrinho[i]
                            let indice = i + 1
                            dados += '&itemId'+indice+'='+item.id
                            dados += '&itemDescription'+indice+'='+item.title
                            dados += '&itemAmount'+indice+'='+item.price
                            dados += '&itemQuantity'+indice+'='+item.quantity
            }
            fetch(urlv,{
                method: "POST",
                headers: { "Content-Type":"application/x-www-form-urlencoded" },
                body: dados
            })
            .then((response) => {
            if (response.ok) {
                return response.json();
            }
            })
            .then((data) => {
                if (data.status == 'falha') {
                  this.$bvModal.msgBoxOk(data.message)
                    return false
                } else {
                    this.loading = true
                    const url = this.baseurl +'AprovePaypal.php'
                    dados = "clientid="+this.ClientId
                    dados += '&gateway=2'
                    dados += '&empresa='+this.empid
                    dados += '&total='+this.Total
                    dados += '&codfrete='+tipoenv
                    dados += '&vlrfrete='+this.Frete
                    dados += '&descfrete'+this.DescFrete
                    dados += '&vlrliquido='+this.Liquido
                    dados += '&qtditens='+this.QtdCarrinho
                    /*
                    let extra = this.VlrCupom + this.TotalDesc
                    if (extra > 0) {
                        extra = extra * -1
                        dados += '&extraAmount='+extra
                    }
                    */
                    for (let i = 0; i < this.itensCarrinho.length; i++) {
                                let item = this.itensCarrinho[i]
                                let indice = i + 1
                                dados += '&itemId'+indice+'='+item.id
                                dados += '&itemDescription'+indice+'='+item.title
                                dados += '&itemAmount'+indice+'='+item.price
                                dados += '&itemQuantity'+indice+'='+item.quantity
                    }
                    fetch(url,{
                                method: "POST",
                                headers: { "Content-Type":"application/x-www-form-urlencoded" },
                                body: dados
                            })
                            .then((response) => {
                                    if (response.ok) {
                                    
                                        return response.json();
                                }
                                })
                                .then((json) => {
                                        this.loading = false
                                        if (json.status == 'sucesso') {
                                            this.$store.commit('setPdId',json.id)
                                            this.linkf2b = json.message
                                            this.cobgerada = true
                                        } else {
                                            alert(json.message)
                                        }
                                })
                                .catch(function (error) {
                                    console.log(error);
                                })
                }  

            })
            .catch(function (error) {
            this.error = error;
            });


       }
   },
   watch: {
        cobgerada: function(newValue) {
           if (newValue) {
               const h = this.$createElement
        // Using HTML string
        const titleVNode = h('div', { domProps: { innerHTML: 'Cobrança F2b' } })

                       // More complex structure
        const messageVNode = h('div', { class: ['foobar'] }, [
          h('p', { class: ['text-center'] }, [
            ' Clique no  ',

           h('b-link',{ 
                props: { 
                    href: this.linkf2b,
                    target:'_blank'
                } },'Link de Cobrança Gerado'),
           ]),

            'e será direcionado para o site Cobrança F2b. Lá poderá escolher pagar no pix, debito ou gerar boleto. Se clicar no botão Gerar Boleto será registrado no sistema bancario em até 2 horas, quando poderá efetuar o pagamento ',
            ])

 // We must pass the generated VNodes as arrays
        this.$bvModal.msgBoxOk([messageVNode], {
          title: [titleVNode],
          buttonSize: 'sm',
          centered: true, size: 'sm'
        })
         
        
       
            }
       }
   }

}
</script>

<style scoped>

</style>