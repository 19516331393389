<template>
 <!-- header -->
	<header>
	<div>
  <b-navbar toggleable="lg" style="background-color:white" type="light"  >
    <b-navbar-brand :to="{ name: 'Home'}">
    <img id='logo' src="https://www.madamesobral.com.br/assets/img/final.svg" alt="Madame Sobral">
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse" ></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav >
      <b-navbar-nav variant="primary" type="dark">

        


       <a href="/#" class="btn br-5" v-bind:id=item.id v-for="item in itensmenu" :key="item.id" v-on:click.self.prevent="changeCateg($event)">{{item.nome}}</a>
       <b-nav-item href="https://api.whatsapp.com/send?phone=5531983493751&text=Ola ! Gostaria de conversar com vocês !">
       

           <b-icon id='chat' icon="chat-left-text" variant="danger"></b-icon>
          
        </b-nav-item>

      <b-nav-item-dropdown left>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <b-icon icon="person-circle" variant="danger"></b-icon>
          </template>
          <b-dropdown-item v-if="!logado" @click="$bvModal.show('modal-prevent-login')"
          v-b-tooltip.hover.html="tipData" 
          >Entrar</b-dropdown-item>
         
         
          <b-dropdown-item v-if="logado" @click="sair()">Sair</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item v-if="logado" href="#">Olá {{nomeusuario}}</b-nav-item>
        
      
        <b-nav-item v-if="logado" :to="{ name: 'Pedidos'}" >Pedidos</b-nav-item>

    
       
      </b-navbar-nav>
      

    
    </b-collapse>
    <madame-side-bar-cart class="mr-2"></madame-side-bar-cart>
  </b-navbar>
</div>		

		
	</header>
		<!-- end header -->
</template>

<script>

import MadameSideBarCart from './MadameSideBarCart.vue'

export default {
  components: { MadameSideBarCart },
    data: function () {
		return {
           
      itensmenu:[],
      tipData: { title: '<em>Para ver Pedidos e ter uma experiência personalizada acesse sua conta</em>' },
      tipData2: { title: '<em>É Cliente novo ? Cria sua conta para ver Pedidos e ter uma experiência personalizada</em>' }

        }  
    },
    computed: {
        baseurl: function() {
          return this.$store.getters.geturlbackend
        },
         logado() {
          return this.$store.getters.getLogado
        },
        nomeusuario() {
          return this.$store.getters.getNomeLog
        },
        qtdcart() {
          return this.$store.getters.getQtdCarrinho
        }
    },
    methods: {
        getItensMenu() {
			const url = this.baseurl+'ajax_categorias.php'
			fetch(url)
			.then((response)  => { 

				if (response.ok) {
					console.log('itens menu ok')
					return response.json()
                }
            })
			.then((data) => {
                this.itensmenu = data
            })
			.catch(function(error) {
                console.log(error)
			})
		},
      changeCateg(e) {
            
            this.$store.commit("setCategoria",e.target.id)
            let payload = {
                'categoria':e.target.id,
                'baseurl':this.baseurl 
            }
            this.$router.push('/')
            this.$store.dispatch('carregarProdutosCat',payload)
      },
      sair() {
        console.log('vou sair')
    
        this.$store.dispatch('logout',false)
        let cat = {
            'categoria':2,
            'baseurl':this.baseurl 
        }
        this.$router.push({ path:'/'})
        this.$store.dispatch('carregarProdutosCat',cat)
        
      } 
    },
    created() {
      this.$store.dispatch("loadempresa")
    },
    mounted() {
        this.getItensMenu()
    }

}    
</script>

<style scoped>
header {

    background-color:#f7c2f2 !important;
}

.navbar-dark .navbar-nav .nav-link {
    color: black;
}

#nav-collapse {
  display:flex;
  justify-content:center;
  margin:20px;
  
}

#logo {
	height:100%;
	width: 150px;
    margin-left: 1em;
} 
#botaocarrinho {
  display:flex;
  justify-content:flex-end;
}


 a {
	color:black;
    display:flex;
    justify-content: center;

   
}
 a:hover {
	color:#d25b5b;

}

a:focus {
	outline: none !important;
	box-shadow: none !important;
    background-color: #921543;
    color:white;
}

.link {
	color:#d25b5b;
   
}


</style>