<template>
    <div class="container corfundo p-5">
       <b-card-group deck>
            
             <madame-single-prod v-for="prod in getprodutos"
                v-bind:key="prod.id"
                v-bind:foto="prod.image"
                v-bind:prod="prod"
                
                > 
        </madame-single-prod>
            
      </b-card-group>      
            
    </div>
</template>

<script>
import MadameSingleProd from "../components/MadameSingleProd.vue"
import { mapGetters } from "vuex";
export default {
    components: {
        MadameSingleProd

    },
    data() {
        return {
            loading:false,
            erro:'',
            show:false
        }

    },
    computed: {

        ...mapGetters(["getprodutos","geturlbackend","getcategoria"])

    },
    methods: {
      
    },
    mounted() {
        console.log('created')
        let payload = {
            'categoria':this.getcategoria,
            'baseurl':this.geturlbackend
           
        }
        this.$store.dispatch('carregarProdutosCat',payload)

    }
    
   
}
</script>

<style scoped>
  .corfundo {
      background-color:white;
  }

</style>