<template>

 <b-card class="mt-2">
    <form>
        <div class="form-group">
        <input type="text" class="form-control" v-model="cep" id="fretecep" v-on:change="calcularfrete" aria-describedby="emailHelp" placeholder="Seu Cep">
        <small id="emailHelp" class="form-text text-muted">Cep que sera utilizado para calular o frete.</small>
        </div>
    </form>
    <div id="dadosfrete">
        <b-form-select id='opfrete' v-model="selected" :options="options" :select-size="4" v-on:change="atribuifrete"></b-form-select>
        <small id="opfrete" class="form-text text-muted">Se estiver no Celular clique no campo acima para aparecer opções frente</small>
        <div class="mt-3">Frete Escolhido: {{ this.$store.getters.getdescfrete }} {{ this.$store.getters.getFrete  | currency("R$") }} </div>
    </div>
</b-card>

</template>

<script>
export default {
    data() {
        return {
            dadosfrete:[],
            options:[],
            cep:'',
            selected: this.$store.getters.getCodFrete
        }
    },
   
    computed: {
        baseurl: function() {
          return this.$store.getters.geturlbackend
        },
        empresa: function() {
            return this.$store.getters.getempresa;
        },
       
    },
    methods: {
      atribuifrete() {
         this.$store.commit("setFrete",this.selected.preco)
         this.$store.commit("setCodFrete",this.selected.codigo)
         this.$store.commit("setdescfrete",this.selected.nome)
         this.$store.commit("setcepfrete",this.cep)

      },
      calcularfrete() {
        
        const url = this.baseurl+'ajax_calcfrete.php'
        console.log('chamando ajax '+url)
        const dataJson = 'cep='+this.cep+'&emp='+this.empresa
        console.log(dataJson);
        fetch(url ,{
                    method: "POST",
                    headers: { "Content-Type":"application/x-www-form-urlencoded" },
                    body: dataJson
               } )
        .then((response) =>  { return response.json() })
         .then((data) => {
           
           this.dadosfrete = data;  
           this.options = []
          
           data.forEach(obj => {


           let item = {
                text: obj.servico,
                value: { codigo: obj.codserv,
                         nome: obj.servico,
                         dias: obj.dias,
                         preco: obj.preco }
                       
             }
            
             this.options.push(item) 

              });


        })
        .catch(function (error) {
          console.log(error);
          this.error = error;
        });
      }

    },
    created() {
      if (this.$store.getters.getcepfrete != '') {
        this.cep = this.$store.getters.getcepfrete
        this.calcularfrete()

      }
    }

  
}
</script>

<style scoped>

</style>