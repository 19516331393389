<template>

<b-card
    header="Resumo do Pedido"
    header-tag="header"
    footer-tag="footer" 
    bg-variant="light"
>
<b-row no-gutters  v-for="item in this.itensCarrinho" :key="item.id">
      <b-col md="6">
        {{item.quantity}} {{ item.title }}
      </b-col>
      <b-col md="6">
       {{ item.price | currency("R$") }}
      </b-col>
</b-row> 
<b-row>
 <madame-endereco v-show='Logado'></madame-endereco> 
</b-row>
<hr>
<madame-totais-cart></madame-totais-cart>
<b-row>
  <b-form-group label="Forma de Pagamento" v-slot="{ ariaDescribedby }">
      <b-form-radio v-model="formapag" :aria-describedby="ariaDescribedby" name="forma-pag" value="P" >Paypal (Cartão)</b-form-radio>
      <b-form-radio v-model="formapag" :aria-describedby="ariaDescribedby" name="forma-pag" value="B" >Boleto</b-form-radio>
    </b-form-group>
</b-row>
<template #footer>
<div class="text-center">
<madame-f2b-botao v-show="emailvalidado && formapag == 'B' "></madame-f2b-botao>
<madame-paypal-botao v-if="emailvalidado && formapag == 'P' "> </madame-paypal-botao>

<b-spinner v-show="loading" variant="success" ></b-spinner>
</div>
<div class="text-center mt-2">
</div>
  <b-modal id="modal-center" centered title="Alerta">
    <p class="my-4">{{msgmodal}}</p>
  </b-modal>
</template>
</b-card>

</template>

<script>
import { mapGetters } from "vuex"
import MadameTotaisCart from "./MadameTotaisCart.vue"
import MadameF2bBotao from "./MadameF2bBotao.vue"
import MadamePaypalBotao from "./MadamePaypalBotao.vue"
import MadameEndereco from "./MadameEndereco.vue"

export default {
    components: {
        MadameTotaisCart,
        MadameF2bBotao,
        MadamePaypalBotao,
        MadameEndereco
    },
    data() {
          return {
              code:'',
              json:'',
              loading:false,
              link:'',
              msgmodal:'',
              formapag: '',
              emailvalidado: false
          }
    },
    computed: {
       ...mapGetters({
        itensCarrinho: "getCartItems", 
        Logado:'getLogado',
        ClientId:'getClientId'
         }),
    },
    watch: {
      formapag(newValue) {
        this.valida_email()
        console.log('forma '+newValue)
      }
    },
    methods: {
              valida_email() {
        this.loading = true;
        const url =this.$store.getters.geturlbackend + "ajax_email_validado.php"
        const dataJson = 'cl='+this.ClientId
        fetch(url,{
                    method: "POST",
                    headers: { "Content-Type":"application/x-www-form-urlencoded" },
                    body: dataJson
               })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((data) => {
               this.loading = false;
              if (data.status == "sucesso") {
                  this.emailvalidado = true
               // this.enviarcheckout()

              } else {
                this.emailvalidado = false;
                this.formapag = ''
                this.msgmodal = data.message
                this.$bvModal.show('modal-center')
 
            }  

           
          })
          .catch(function (error) {
            this.error = error;
          });
        }
    }
}
</script>

<style scoped>

</style>