<template>
  <div id="app">
    <madame-header >
    </madame-header>
    <keep-alive>
			<router-view></router-view>
		</keep-alive>

  </div>
</template>

<script>
import MadameHeader from "./components/MadameHeader.vue"


export default {
  name: 'App',
  components: {
     MadameHeader,

  },
  data: function() {
    return {
        categid:2
    }
  },
  methods: {
     filtrarcateg(event) {
       console.log('app filtrarcateg: '+event)
       this.categid = event;
     }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

</style>


