<template>
<b-container>
  <!-- Content here -->
        <b-row no-gutters v-if="ValorFrete > 0" >
            <b-col md="8" >
              <span  class="text-black m-2"><strong>Total</strong> </span>     
            </b-col>
            <b-col md="4">
              <span class="direita"><strong>{{ Total | currency("R$") }}</strong> </span>
            </b-col>
        </b-row>
        <b-row no-gutters v-if="ValorFrete > 0">
            <b-col md="8" >
              <span  class="text-black m-2"><strong>(+) {{ this.$store.getters.getdescfrete }}</strong> </span>     
            </b-col>
            <b-col md="4">
              <span class="direita"><strong>{{ ValorFrete | currency("R$") }}</strong> </span>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col md="8">
              <span class="text-danger m-2"><strong>Total</strong> </span>
            </b-col>
            <b-col md="4">
              <span class="text-danger direita"><strong> {{ Liquido | currency("R$") }}</strong> </span>
            </b-col>
        </b-row>
        <hr>
</b-container>    
</template>
<script>
import { mapGetters } from "vuex";
export default {
   computed: {
        ...mapGetters({
      Total:'getTotalCarrinho',
      QtdCarrinho:'getQtdCarrinho',
      Logado:'getLogado',
      ClientId:'getClientId',
      ValorFrete:'getFrete',
      Frete:'getFrete',
      

      }),
     
      
      Liquido() {
        console.log('Total '+this.Total+' Frete '+this.ValorFrete)
        let liq = parseFloat(this.Total) + parseFloat(this.ValorFrete) 
        console.log(liq)
        return liq
      },
   }    
}
</script>
<style scoped>
.direita {
    float:right !important;
    padding-right:15px;
}
</style>