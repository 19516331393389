import Vue from 'vue'
import App from './App.vue'
import store from './store/store'
import Vue2Filters from 'vue2-filters'
import Maska from 'maska'
import { BootstrapVue,  BootstrapVueIcons } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router/router'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(BootstrapVueIcons)
Vue.use(Vue2Filters)
Vue.use(Maska)



Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),

  
}).$mount('#app')
