import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        baseurl:'https://www.madamesobral.com.br/',
        categid:2,
        pdid:0,
        produtos:[],
        loadingProd:false,
        erroProd:'',
        cartItems:[],
        logado:false,
        nomelog:'',
        msglog:'',
        clientid:0,
        contacriada:false,
        msgconta:'',
        frete:0.00,
        codfrete:'',
        cepfrete:'',
        descfrete:'',
        endlogradouro:'',
        endnum:'',
        endcompl:'',
        endbairro:'',
        endcidade:'',
        enduf:'',
        empresa:1,
        isPromo:false,
        promocao:'',
        promoperc:0,
        finalprom:'',
        isFreteGratis:false,
        finalfrete:''

    },
    getters: {
        getendlogradouro(state) {
            return state.endlogradouro
        },
        getendnum(state) {
            return state.endnum
        },
        getendcompl(state) {
            return state.endcompl
        },
        getendbairro(state) {
            return state.endbairro
        },
        getendcidade(state) {
            return state.endcidade
        },
        getenduf(state) {
            return state.enduf
        },
        getempresa(state) {
            return state.empresa
        },
        getispromo(state) {
            return state.isPromo
        },
        getpromocao(state) {
            return state.promocao
        },
        getfinalpromo(state) {
            return state.finalprom
        },
        getpromoperc(state) {
            return state.promoperc
        },
        getisfretegratis(state) {
            return state.isFreteGratis
        },
        getfinalfrete(state) {
            return state.finalfrete
        },
        getcepfrete(state) {
            return state.cepfrete
        },
        getdescfrete(state) {
            return state.descfrete
        },
        geturlbackend(state) {
            return state.baseurl                
        },
        getprodutos(state) {
            return state.produtos
        },
        getcategoria(state) {
            return state.categid
        },
        getqtdeitens(state) {
            return state.produtos.length
        },
        getCartItems(state) {
            return state.cartItems
        },
        getQtdCarrinho(state) {
            return state.cartItems.length                
        },
        getTotalCarrinho(state) {
            let total = 0;
			state.cartItems.forEach(item => {
				total += (item.price * item.quantity);
			});

			return total;
        },
        getMsgConta(state) {
            return state.msgconta
        },
        getLogado(state) {
            return state.logado                
        },
        getNomeLog(state) {
            return state.nomelog
        },
        getClientId(state) {
            return state.clientid
        },
        getMsgLog(state) {
            return state.msglog
        },
        getFrete(state) {
            return state.frete
        },
        getCodFrete(state) {
             return state.codfrete
        },
        getPdId(state) {
            return state.pdid
        },
        getContaCriada(state) {
            if (state.contacriada == "sucesso") {
                return true
            } else {
                return false
            }
         },
       
    },
    mutations: {
        setempresa(state,payload) {
            state.empresa = payload
        },
        setispromo(state,payload) {
            state.isPromo = payload
        },
        setpromocao(state,payload) {
            state.promocao = payload
        },
        setpromoperc(state,payload) {
            state.promoperc = payload
        },
        setfinalpromo(state,payload) {
            state.finalprom = payload
        },
        setisfretegratis(state,payload) {
            state.isFreteGratis = payload
        },
        setfinalfrete(state,payload) {
            state.finalfrete = payload
        },
        setcepfrete(state,payload) {
            state.cepfrete = payload
        },
        setdescfrete(state,payload) {
            state.descfrete = payload
        },
        setProdutos(state,payload) {
            state.produtos = payload
        },
        setCategoria(state,payload) {
            state.categid = payload
        },
        addItemCart(state,payload) {
            state.cartItems.push(payload)
        },
        delItemCart(state,payload) {
            state.cartItems.splice(payload, 1);
        },
        setLogado(state,payload) {
            state.logado = payload
        },
        setNomeLog(state,payload) {
            state.nomelog = payload
        },
        setMsgLog(state,payload) {
            state.msglog = payload
        },
        setContaCriada(state,payload) {
            state.contacriada = payload
        },
        setMsgConta(state,payload) {
            state.msgconta = payload
        },
        setClientId(state,payload) {
            state.clientid = payload
        },
        setFrete(state,payload) {
            state.frete = payload
        },
        setCodFrete(state,payload) {
            state.codfrete = payload
        },
        setPdId(state,payload) {
            state.pdid = payload
        },
        setendlogradouro(state,payload) {
            state.endlogradouro = payload
        },
        setendnum(state,payload) {
            state.endnum = payload
        },
        setendcompl(state,payload) {
            state.endcompl = payload
        },
        setendbairro(state,payload) {
            state.endbairro = payload
        },
        setendcidade(state,payload) {
            state.endcidade = payload
        },
        setenduf(state,payload) {
            state.enduf = payload
        }
    },

    actions: {
        loadempresa(context) {
            const url = context.state.baseurl+'ajax_get_home.php'
            fetch(url)
            .then((response) => { return response.json() 
            })
            .then((data) => {

               
                context.commit("setispromo",data[0].isPromo)
                context.commit("setpromocao",data[0].nomepro)
                context.commit("setpromoperc",data[0].percpromo)
                context.commit("setfinalpromo",data[0].finalpro)
                context.commit("setisfretegratis",data[0].isFreteGratis)
                context.commit("setfinalfrete",data[0].finalfrete)
            })
            .catch(function(error) {
                console.log('Erro loadempresa site: '+error);
            }) 
        },
        logar(context,payload) {
            const url = context.state.baseurl+'ajax_logar.php'
            const dataJson = 'email='+payload.email+'&senha='+payload.senha
            //const dataJson = JSON.stringify(payload)
            console.log(url)
              fetch(url,{
                   method: "POST",
                   headers: { "Content-Type":"application/x-www-form-urlencoded" },
                   body: dataJson
              })
              .then((response)  => { return response.json()
              })
              .then((data) => {
                  if (data.sucesso) {
                    context.commit("setNomeLog",data.nome)
                    context.commit("setLogado",data.sucesso)
                    context.commit("setClientId",data.cfid)
                    context.commit("setendlogradouro",data.logradouro)
                    context.commit("setendnum",data.numero)
                    context.commit("setendcompl",data.compl)
                    context.commit("setendbairro",data.bairro)
                    context.commit("setendcidade",data.cidade)
                    context.commit("setenduf",data.uf)
                    context.commit("setcepfrete",data.cep)
                  }
                  
                  context.commit("setMsgLog",data.msg)

              })
              .catch(function(error) {
                      console.log('Erro logando site: '+error);
              })
           
       },
       logout(context,payload) {
        context.commit("setLogado",payload)
        context.commit("setClientId",0)
        context.commit("setNomeLog","")
       
       
       },
       criarconta(context,payload) {
           const url = context.state.baseurl+'ajax_cria_conta.php'

           const dados =   'empresa='+context.state.empresa+'&'+
                            'id='+payload.id+'&'+
                           'nome='+payload.nome+'&'+
                           'cpf='+ payload.cpf+'&'+
                           'cnpj='+ payload.cnpj+'&'+
                           'razao='+payload.razao+'&'+
                           'email='+payload.email+'&'+
                           'senha='+payload.senha+'&'+
                           'tipopessoa='+payload.tipopessoa+'&'+
                           'endereco='+payload.endereco+'&'+
                           'endnum='+payload.endnum+'&'+
                           'compl='+payload.compl+'&'+
                           'bairro='+payload.bairro+'&'+
                           'cidade='+payload.cidade+'&'+
                           'uf='+payload.uf+'&'+
                           'cep='+payload.cep+'&'+
                           'telcom='+payload.telcom+'&'+
                           'celular='+payload.celular 
                           console.log(url)
                           fetch(url,{
                                method: "POST",
                                headers: { "Content-Type":"application/x-www-form-urlencoded" },
                                body: dados
                           })
                           .then((response)  => { return response.json()
                           })
                           .then((data) => {
                               console.log(data)
                               if (data.status == "sucesso") {
                                  context.commit("setNomeLog",payload.nome)
                                  context.commit("setLogado",true)
                                  context.commit("setClientId",data.id)

                                  if (data.message.includes('criada')) {
                                    console.log('vou dar dispatch no envia_email_modelo para id = '+data.id)
                                    // Se incluiu novo usuario do site enviar email para confirmar nova conta
                                    context.dispatch("envia_email_confirmacao",{
                                       cliente : data.id // codigo novo cliente inserido via ajax
 
                                    })     
                                 }



                               } 
                               context.commit("setMsgConta",data.message)
                           })
                           .catch(function(error) {
                                   console.log('Erro Envio Criação da Conta: '+error);
                           })                            


       },
       envia_email_confirmacao(context,payload) {
        const url = context.state.baseurl+'ajax_email_confirmaconta.php'

        const dados =   'cliente='+ payload.cliente
        fetch(url,{
            method: "POST",
            headers: { "Content-Type":"application/x-www-form-urlencoded" },
            body: dados
        })
        .then((response)  => { return response.json()
        })
        .then((data) => {
                console.log('Envio Email '+data.message);          
               // context.commit("setEnviouParceiro",data.status)
               // context.commit("setMsgParceiro",data.message)
         
        })
        .catch(function(error) {
               console.log('Erro Envio Confirmacao Email Cliente: '+error);
        })                   

    },
       carregarProdutosCat(context,payload) {
         
            const url = payload.baseurl+'ajax_get_produtos.php?cat='+payload.categoria
              fetch(url)
              .then((response)  => { return response.json()
              })
              .then((data) => {
                 
                  context.commit("setProdutos",data)

              })
              .catch(function(error) {
                      console.log('Erro Carregando Prod Categoria: '+error);
                      
              })
           
       }
    }
})
