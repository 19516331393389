<template>
<div>
  <b-card
    v-bind:title="prod.nome"
    v-bind:img-src="imgfoto"
    img-alt="Image"
    img-top
    tag="article"
    style="max-width: 18rem;"
    class="mb-3"
  >
   
    <b-card-text v-html="prod.descricao">
  
    </b-card-text>

   <div class="alert alert-danger" role="alert" v-if="prod.percdesc > 0">

    De <span class='old-price font-weight-normal '>{{ prod.precovenda | currency("R$") }}</span> por {{ prod.price | currency("R$") }}
    </div> 
   <div class="alert alert-danger text-center" role="alert" v-else>
    {{ prod.price | currency("R$") }}
    </div> 
    <template #footer>
        <b-button block href="#" variant="danger" @click="clickAdd(prod,$event)">
          <b-icon icon="cart-plus"></b-icon>
          Adicionar ao Carrinho
        </b-button>      
    </template>

    </b-card>
</div>    
</template>

<script>
import { mapGetters } from "vuex";
export default {
     props: ['prod','foto'],
     computed: {
        baseurl: function() {
          return this.$store.getters.geturlbackend
        },
         imgfoto: function() {
               return this.baseurl+'/'+this.foto

         } ,
          ...mapGetters(['getCartItems'])
         
     },
     methods: {
          clickAdd: function (prod,e) {
            
          console.log(e)
           
          let itemToAdd = prod;
         
          // add the item or increase quantity
          let itemInCart = this.getCartItems.filter(
            (item) => item.id === itemToAdd.id
          );
          let isItemInCart = itemInCart.length > 0;

          if (isItemInCart === false) {
            this.$store.commit('addItemCart',itemToAdd)
            

            this.$bvToast.toast('Item adicionado com sucesso', {
              title: "Adicionado ao carrinho",
              variant: 'success',
              autoHideDelay: 1000,
              solid: true
            })


          } else {
            itemInCart[0].quantity += itemToAdd.quantity;
          }
          itemToAdd.quantity = 1;
        }
     }
     

}
</script>
  


<style scoped>
.btn-danger {
  color:#fff;
  background-color: #921543;
  border-color: #921543;
}
p {
      font-family: 'Poppins' !important;
  }
.old-price {
  
   text-decoration: line-through;
}  
</style>