<template>
  <b-card title="Meu Endereço" class="mt-2">
    <b-card-text>
       {{endereco}}
    </b-card-text>

    <b-card-text>{{endcid}}</b-card-text>

     <madame-conta-modal tituloBotaoModal="Alterar Endereço" ></madame-conta-modal>

            <b-card-text v-if="this.$store.getters.getisfretegratis">
                <b-alert show variant="warning">Frete Grátis</b-alert>
            </b-card-text> 
            <b-card-text v-else>
                <b-button v-b-modal.modal-1 class='mt-2'>Calcular Frete</b-button>
                <b-modal id="modal-1" title="Calculo de Frete">
                <madame-frete></madame-frete>
                </b-modal>
            </b-card-text>
       
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import MadameContaModal from "./MadameContaModal.vue"
import MadameFrete from './MadameFrete.vue';


export default {
     components: {
         MadameContaModal,
         MadameFrete
     },
     computed: {
    
        ...mapGetters({
      Logradouro:'getendlogradouro',
      Bairro:'getendbairro',
      Cidade:'getendcidade',
      UF:'getenduf',
      Compl:'getendcompl',
      Num:'getendnum',
      Cep:'getcepfrete'
      }),
      endereco() {
          let aux = this.Logradouro + ',' + this.Num;
          if (this.Compl != '') {
              aux = aux +' '+this.Compl;
          } 
          aux = aux + this.Bairro;
          return aux;
      },
      endcid() {
        return this.Cep+' '+this.Cidade+' - '+this.UF
      }
    }
}    
</script>
<style>

</style>