<template>

<div>
    <button class="mb-2" v-on:click="paymethod">Opções de Pagamento Paypal </button> 
    <b-spinner v-show="loading" variant="success" ></b-spinner>
    <div ref="paypal"></div>
    <div v-if="paidFor">
        <b-alert variant="success" show>Pagamento efetuado com sucesso ! Transação Paypal:{{transacao}} Agradecemos sua compra ! Verifique seu email</b-alert>
	<h2>  </h2>
	</div>
</div>

</template>

<script>
import { mapGetters } from "vuex";
export default {


data: function() {
    return {
      loading:false,  
      loaded: false,
      paidFor: false,
      transacao:''
    }  
},

  methods: {
    paymethod() {
           console.log('paymethod')
           console.log('fretegratis '+this.fretegratis+' Codfrete:'+this.CodFrete)
           if (!this.fretegratis) {
               if (this.CodFrete == '') {
                    this.$bvModal.msgBoxOk('Frete Deve ser Calculado Antes !')
                    return false
               } 
               
            
            }
            const urlv = this.baseurl +'ajax_valida_estoque.php'
            var dados = 'qtditens='+this.QtdCarrinho
            for (let i = 0; i < this.itensCarrinho.length; i++) {
                            let item = this.itensCarrinho[i]
                            let indice = i + 1
                            dados += '&itemId'+indice+'='+item.id
                            dados += '&itemDescription'+indice+'='+item.title
                            dados += '&itemAmount'+indice+'='+item.price
                            dados += '&itemQuantity'+indice+'='+item.quantity
            }
            fetch(urlv,{
                method: "POST",
                headers: { "Content-Type":"application/x-www-form-urlencoded" },
                body: dados
            })
            .then((response) => {
            if (response.ok) {
                return response.json();
            }
            })
            .then((data) => {
                if (data.status == 'falha') {
                  this.$bvModal.msgBoxOk(data.message)
                    return false
                } else {

                    this.loading = true
                    const url = this.baseurl + "ajax_get_clientid_paypal.php?id="+this.empid
                    fetch(url)
                    .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    })
                    .then((data) => {

                        const script = document.createElement("script");
                        script.src = data.message
                        script.addEventListener("load", this.setLoaded);
                        document.body.appendChild(script);

                    })
                    .catch(function (error) {
                    this.error = error;
                    });



                }   

            })
            .catch(function (error) {
            this.error = error;
            });




       
    },
    setLoaded: function() {
      this.loaded = true;

      this.loading = true;
      const url = this.baseurl + "ajax_get_cliente.php?id="+this.ClientId+'&e='+this.empid;
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((cliente) => {

            
            console.log(cliente)
            let itens = []
            this.itensCarrinho.forEach( (element) => {
                itens.push({name:element.title , 
                            sku:element.id , 
                            unit_amount: { 
                                currency_code:"BRL",
                                value:element.price
                            },
                            quantity : element.quantity   })
            })    
            window.paypal
                .Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                    payer: {
                        name: {
                            given_name: cliente.first_name,
                            surname: cliente.last_name
                        },                            
                   
                        address: {
                            address_line_1: cliente.address1,
                            address_line_2: cliente.address2,
                            admin_area_2: cliente.city,
                            admin_area_1: cliente.state,
                            postal_code: cliente.zip,
                            country_code: cliente.country  
                        },   
                        email_address: cliente.email 
                     },    
                    purchase_units: [
                        {
                            description: cliente.emnome,
                            amount: {
                                currency_code: "BRL",
                                value: this.Liquido,
                                breakdown: {
                                    item_total: {
                                        currency_code: "BRL",
                                        value: this.Total
                                    },
                                    shipping: {
                                        currency_code:"BRL",
                                        value: this.Frete
                                    }
                                }                               
                            },
                            items: itens




                           
                        }
                    ]
                    })
                   
                },
                onApprove: async (data, actions) => {
                    const order = await actions.order.capture();
                    this.paidFor = true;
                    console.log(order);
                    this.transacao = order.id

                    const url = this.baseurl +'AprovePaypal.php'
                    let dados = "clientid="+this.ClientId
                        dados += '&gateway=1'
                        dados += '&empresa='+this.empid
                        dados += '&transacao='+order.id
                        dados += '&total='+this.Total
                        dados += '&codfrete='+this.CodFrete
                        dados += '&descfrete'+this.DescFrete
                        dados += '&vlrfrete='+this.Frete
                        dados += '&vlrliquido='+this.Liquido
                        dados += '&qtditens='+this.QtdCarrinho
                        /*
                         let extra = this.VlrCupom + this.TotalDesc
                         if (extra > 0) {
                            extra = extra * -1
                            dados += '&extraAmount='+extra
                         }
                         */
                         for (let i = 0; i < this.itensCarrinho.length; i++) {
                            let item = this.itensCarrinho[i]
                            let indice = i + 1
                            dados += '&itemId'+indice+'='+item.id
                            dados += '&itemDescription'+indice+'='+item.title
                            dados += '&itemAmount'+indice+'='+item.price
                            dados += '&itemQuantity'+indice+'='+item.quantity
                         }
                        fetch(url,{
                                 method: "POST",
                                 headers: { "Content-Type":"application/x-www-form-urlencoded" },
                                 body: dados
                        })
                            .then((response) => {
                                if (response.ok) {
                                
                                    return response.json();
                            }
                            })
                            .then((json) => {
                                    if (json.status == 'sucesso') {
                                        this.$store.commit('setPdId',json.id)
                                    }
                            })
                            .catch(function (error) {
                                console.log(error);
                            })



                },
                onError: err => {
                    console.log(err);
                }
                })
                .render(this.$refs.paypal);
                
                this.loading = false;
        })
        .catch(function (error) {
          this.error = error;
        });
      } ,

  },  
     computed: {
        ...mapGetters({
            baseurl:'geturlbackend',
            empid:'getempresa',
            itensCarrinho: "getCartItems",
            Total:'getTotalCarrinho',
            QtdCarrinho:'getQtdCarrinho',
            CodFrete:'getCodFrete',
            DescFrete:'getdescfrete',
            Frete:'getFrete',
            fretegratis:'getisfretegratis',
            ClientId:'getClientId'
           
        }),
        
         Liquido() {
            return parseFloat(this.Total) + parseFloat(this.Frete)
        }
   }   
}
</script>

<style scoped>

</style>