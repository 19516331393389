<template>
  <div >
    <b-button v-b-toggle.sidebar-footer variant="danger">
     <b-icon icon="cart">
     </b-icon>
     <b-badge class="ml-2" variant="light">{{ QtdCarrinho }}</b-badge>
     
    </b-button>
    <b-sidebar id="sidebar-footer"  right shadow>
     
      <div class="px-2 py-2">
        <div v-show="!comprar" class="col-12">

          <madame-mini-cart></madame-mini-cart>
          <madame-totais-cart></madame-totais-cart>
          
         

         <b-row align-h="end" class="m-2">
            <b-col cols="4" >    
            <b-button variant="success" size="sm" v-on:click="iniciarTabCompra">Comprar</b-button>
            </b-col>
         </b-row>

        </div> <!--coluna 1 !Comprar -->


      <div v-show="comprar">
       
        <b-button-toolbar class="mb-2" key-nav aria-label="Toolbar with button groups">
            <b-button-group class="mx-1">
            <b-button v-show="Logado && tabIndex == 0" @click="tabIndex++">Continuar <b-icon icon="arrow-right-circle"></b-icon></b-button>
            <b-button v-show="tabIndex > 0" @click="tabIndex--"><b-icon icon="arrow-left-circle"></b-icon> Voltar</b-button>
            <b-button  @click="voltarTabCompra"><b-icon icon="cart"></b-icon> Carrinho {{QtdCarrinho}}</b-button>
            </b-button-group>
        </b-button-toolbar>

        <div>
        <b-card no-body>
            <b-tabs card v-model="tabIndex">
            <b-tab title="Identificação" active>
                <b-button v-b-modal.modal-prevent-login variant="primary">Ja sou Cliente. Logar</b-button>
                <madame-login-modal ></madame-login-modal>

                <madame-conta-modal tituloBotaoModal="Quero Criar Minha Conta" ></madame-conta-modal>

                <b-alert class='mt-3' v-bind:show="temmsg" v-bind:variant="classemsg"> {{msgconta}}</b-alert>
                 


            </b-tab>
            
            <b-tab title="Finalizar Pedido" >
                <b-card-text title="Revisar Pedido e Pagar"> 
                <madame-resumo></madame-resumo>
                 </b-card-text>
            </b-tab>

            </b-tabs>
        </b-card>
        </div>

      </div><!-- fim coluna 2-->



      </div> <!-- fim row-->
      
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MadameMiniCart from "./MadameMiniCart.vue"
import MadameLoginModal from "./MadameLoginModal.vue"
import MadameContaModal from "./MadameContaModal.vue"
import MadameTotaisCart from "./MadameTotaisCart.vue"

import MadameResumo from "./MadameResumo.vue"

export default {
    data: function() {
      return {
         comprar:false,
         tabIndex:0,
         loading:false        
      }
    },
    components: {
        MadameMiniCart,
        MadameResumo,
        MadameLoginModal,
        MadameContaModal,
        MadameTotaisCart,
       

    },
    computed: {
    
        ...mapGetters({
      Total:'getTotalCarrinho',
      QtdCarrinho:'getQtdCarrinho',
      Logado:'getLogado',
      ClientId:'getClientId',
      ValorFrete:'getFrete',
      Frete:'getFrete',

      }),
     
      
      msgconta() {
            return this.$store.getters.getMsgConta
        },
       temmsg() {
            let aux = this.msgconta
            if (aux.length > 0) {
                return true
            } else {
                return false
            }
        },
         contacriada() {
          return this.$store.getters.getContaCriada
        },
         classemsg() {
            if (this.contacriada) {
                return "success"
            } else {
               return "danger"   
            }
        }

    },
    methods: {
      iniciarTabCompra() {
        this.comprar = true

      } , 
      voltarTabCompra() {
          this.comprar = false         
      },
    },

    watch: {
        Logado: function(newValue) {
            if ( newValue && (this.tabIndex == 0)) {
                this.tabIndex = 1
            }

        },
          mensagem: function (newValue) {
          
               console.log('change mensagem'+newValue)
               this.dismissSecs = 5
              
               this.showAlert()
            
        }
       
    },

}
</script>

<style scoped>
.btn-danger {
  color:#fff;
  background-color: #921543;
  border-color: #921543;
}
.bg-danger {
    background-color: #921543 !important;
}

</style>