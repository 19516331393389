<template>

  
    <div>
    

     <b-modal
      id="modal-prevent-login"
      ref="modal"
      title="Login"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Email"
          label-for="name-input"
          invalid-feedback="Email tem que ser preenchido"
         
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="form.email"
            type="email"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>


        <b-form-group
          label="Senha"
          label-for="name-input"
          invalid-feedback="Senha Correta tem que ser preenchida"
         
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="form.senha"
            type="password"
            :state="senhaState"
            required
          ></b-form-input>
        </b-form-group>


      </form>
    </b-modal>
  </div>
 
</template>

<script>
export default {
    data() {
        return {
           nameState: null,
           senhaState: null,
            form: {
                email: '',
                senha: ''
            }
        }
    },
    computed: {
        logado() {
          return this.$store.getters.getLogado
        },

        mensagem() {
            return this.$store.getters.getMsgLog
        },       
        baseurl() {
            return this.$store.getters.geturlbackend
        }    
    },
    methods: {
          checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.nameState = valid
        return valid
      },
      resetModal() {
       
        this.nameState = null
        this.senhaState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }

        const url = this.baseurl +'ajax_logar.php'
        const dataJson = 'email='+this.form.email+'&senha='+this.form.senha
        fetch(url,{
                    method: "POST",
                    headers: { "Content-Type":"application/x-www-form-urlencoded" },
                    body: dataJson
               })
               .then((response)  => { return response.json()
               })
               .then((data) => {
                  
                if (data.sucesso) {
                    this.$store.dispatch('logar',this.form)
                                // Hide the modal manually
                                this.$nextTick(() => {
                                this.$bvModal.hide('modal-prevent-login')
                                })
                } else {
                    this.$bvModal.msgBoxOk(data.msg)
                    return
                }	
              })
               .catch(function(error) {
                       console.log('Erro logando site: '+error);
               })

       
      },

    }
}

</script>

<style scoped>

</style>