<template>

    <div class="p-2">
      
           
            <b-card  v-for="(item , index) in this.itensCarrinho" :key="item.id" :img-src="urlback+item.image" img-alt="Card image" img-left class="mb-3">
                <b-card-text>
                   <table border="0">
                   <b-row>
                        <td> {{item.title}} </td>  
                        <td><b-icon icon="x-circle" scale="1" variant="danger" @click="removeItem(index)"></b-icon></td>  
                   </b-row>
                   <b-row>
                        <td colspan="2">

                        <b-form-spinbutton 
                                :id="item.id" 
                                v-model="item.quantity" 
                                min="1" max="100">
                            </b-form-spinbutton>
                        </td>
                   </b-row>
                   <b-row>    
                        <td colspan="2">
                            <span style="color:#921543;">{{item.price * item.quantity | currency("R$")}} </span>
                        </td>
                   </b-row>
                   </table>  
                </b-card-text>
               
                   
               

            </b-card>   
          
       
          
            
    </div>




</template>
<script>
import { mapGetters } from "vuex";
export default {
    data: function() {
      return {
         comprar:false,
         tabIndex:0,
         tenhoconta:'accepted'         
      }
  },  
  computed: {
    ...mapGetters({
      itensCarrinho: "getCartItems",
      Total:'getTotalCarrinho',
      QtdCarrinho:'getQtdCarrinho',
      Frete:'getFrete',
      urlback:'geturlbackend'
    }),
    Liquido() {
        return this.Total 
    }
  },
  methods: {
    iniciarTabCompra() {
        this.comprar = true

    } , 
    voltarTabCompra() {
        this.comprar = false         
    },
    removeItem(index) {

        this.$store.commit("delItemCart",index)
         this.$bvToast.toast('Seu item foi removido com sucesso', {
          title: "Item Removido do carrinho",
          variant: 'success',
          autoHideDelay: 5000,
          solid: true
        })
    }
  } 
}
</script>
<style scoped>
b_card {
    display:block
}
img {
    width: 100px;
    height: 100px;
}
.btn-danger {
  color:#fff;
  background-color: #921543;
  border-color: #921543;
}
.text-danger {
  
  color: #921543;

}
</style>