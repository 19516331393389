import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../components/MadameListaProd"
import Login from "../components/MadameLoginModal"
import Conta from "../components/MadameConta"
import Pedidos from "../components/MadamePedidosGrid"
Vue.use(VueRouter)

export default new VueRouter({
    routes: [{
        path: '/',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/conta',
        name: 'Conta',
        component: Conta

    },
    {
        path: '/pedidos',
        name: 'Pedidos',
        component: Pedidos
    },

]
})